<template>
  <section class="section-swiper-wrapper">
    <div v-if="title" class="title-header" :class="{ 'is-centered': isCentered }">
      <div class="title-wrapper">
        <Icon v-if="icon" :name="icon" width="40px" height="40px" class="sm:flex" />
        <!-- eslint-disable-next-line vue/no-v-html -->
        <h2 class="title" v-html="replaceHighlightHtml(title)" />
      </div>
      <div class="right">
        <NuxtLink v-if="link" class="link" :href="link.slug">{{ link.name }} </NuxtLink>
        <SliderSwiperArrows v-model="swiper" class="arrow-left" which="left" />
        <SliderSwiperArrows v-model="swiper" class="arrow-right" which="right" />
      </div>
    </div>
    <slot name="description" />
    <div class="content">
      <slot name="left-content" />
      <SliderSwiperWrapper v-model="swiper" v-bind="sliderProps" class="swiper-wrapper-component">
        <slot name="swiper-elements" />
        <SliderSwiperPoints
          v-if="isPoints"
          v-model="swiper"
          class="points"
          @on-point-click="(index: number) => swiper?.slideTo(index)"
        />
      </SliderSwiperWrapper>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { SlugItem } from '@/lib/types/models/common';
import { SwiperBinding } from '@/lib/types/models/swiper';
import { SliderSwiperWrapperProps } from '@/lib/types/props/index';

withDefaults(
  defineProps<{
    title: string;
    link?: SlugItem;
    sliderProps: SliderSwiperWrapperProps;
    isPoints?: boolean;
    isCentered?: boolean;
    icon?: string;
  }>(),
  {
    link: undefined,
    isCentered: false,
    icon: undefined
  }
);

const swiper = ref<SwiperBinding>();
defineExpose({ swiper });
</script>

<style scoped lang="scss">
@use '$/misc.scss';
@use '$/typography.scss';
@use '$/spacings.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';

.section-swiper-wrapper {
  flex-direction: column;
  width: 100%;

  .title-header {
    display: grid;
    grid-template-columns: 2fr 1fr;

    width: 100%;
    margin-bottom: 48px;
    padding-right: spacings.$desktop-horizontal-lg;
    padding-left: spacings.$desktop-horizontal-lg;

    &.is-centered {
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;

      .title-wrapper {
        grid-column: 2 / 3;

        .title {
          padding-left: 0;
        }
      }
    }

    .title-wrapper {
      gap: 20px;

      .title {
        font-size: typography.$desktop-h2;
        font-weight: 600;
        white-space: break-spaces;

        :deep(strong) {
          color: colors.$green;
        }
      }
    }

    .right {
      gap: 24px;
      align-items: center;
      justify-content: flex-end;

      .link {
        @include misc.bold-link();

        font-size: 20px;
      }
    }
  }

  .content {
    width: 100%;

    .swiper-wrapper-component {
      width: 100%;

      :deep(.swiper) {
        width: auto;
      }

      .points {
        position: absolute;
        z-index: 5;
        bottom: 24px;
        left: 0;

        justify-content: center;

        width: 100%;
      }
    }
  }
}

@include breakpoints.tablet() {
  .section-swiper-wrapper {
    .title-header {
      display: flex;
      flex-direction: column;

      .right {
        justify-content: flex-start;
      }
    }
  }
}

@include breakpoints.mobile() {
  .section-swiper-wrapper {
    .title-header {
      display: flex;
      gap: 12px;
      justify-content: space-between;

      margin-bottom: 16px;
      padding: 0 spacings.$mobile-horizontal;

      .title-wrapper {
        align-items: center;
        justify-content: flex-start;

        .title {
          flex-wrap: wrap;
          gap: 3px;
          font-size: typography.$mobile-h2;
        }
      }

      .right {
        padding-right: 0;

        .link {
          display: none;
        }
      }

      &.is-centered {
        grid-template-columns: 1fr 1fr;

        .title-wrapper {
          grid-column: 1;

          .title {
            padding-left: 0;
          }
        }
      }
    }

    .swiper-wrapper-component {
      grid-column: 1 / 3;
      width: 100%;
    }
  }
}
</style>
